import styled from 'styled-components';
import phoneCodes from 'lib/countryPhoneCodes';

const PhoneNumberInput = ({ register, width }) => (
    <Wrapper width={width}>
        <CountryCodeSelect
            {...register('countryCode')}
            data-testid="address-country-code"
        >
            {phoneCodes.map((code) => (
                <option key={code.code} value={code.dial_code}>
                    {code.dial_code}
                </option>
            ))}
        </CountryCodeSelect>
        <input
            {...register('phoneNumber')}
            type="tel"
            data-testid="address-phone-number"
            maxLength="10"
        />
    </Wrapper>
);
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    label {
        margin-bottom: 3px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input {
        width: ${({ width }) => width || '100%'};
        max-width: 260px;
        margin-left: 20px;
        border-radius: 0;
        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }
`;
const CountryCodeSelect = styled.select`
    width: 60px !important;
    height: 30px !important;
    margin-right: 10px;
    border-radius: 0 !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.primary}`} !important;
    color: ${({ theme }) => theme.colors.primary};
    background: transparent;
    padding: 5px 6px;
`;
export default PhoneNumberInput;
