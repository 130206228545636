import * as yup from 'yup';

const nameNotLongEnough = 'minCharWarning';
const passwordNotLongEnough = 'passwordMinCharWarning';
const invalidEmail = 'enterValidMail';
const passwordTooLong = 'passwordTooLong';
const phoneNumberNotValid = 'validPhoneNumber';
const addressTitleTooLong = 'addressTitleTooLong';
const addressNotLongEnough = 'addressTooShort';
const passwordAreNotSame = 'passwordsNotSame';
const acceptTerms = 'mustAcceptTerms';
const requiredMessage = 'requiredMessage';

export const addressSchema = yup.object().shape({
    name: yup
        .string()
        .required(requiredMessage)
        .min(2, nameNotLongEnough)
        .max(25),
    surname: yup
        .string()
        .required(requiredMessage)
        .min(2, nameNotLongEnough)
        .max(25),
    countryCode: yup.string(requiredMessage).required(requiredMessage),
    addressTitle: yup
        .string()
        .max(128, addressTitleTooLong)
        .required(requiredMessage),
    address: yup
        .string()
        .min(8, addressNotLongEnough)
        .required(requiredMessage),
    phoneNumber: yup
        .string()
        .required(requiredMessage)
        .min(7, phoneNumberNotValid)
        .max(10, phoneNumberNotValid),
    zipCode: yup.string().required(requiredMessage),
});

export const passwordChangeSchema = yup.object().shape({
    currentPassword: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required(),
    newPassword: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required(),
    newPasswordAgain: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required()
        .oneOf([yup.ref('newPassword')], passwordAreNotSame),
});

export const signupSchema = yup.object().shape({
    first_name: yup
        .string()
        .required(nameNotLongEnough)
        .min(2, nameNotLongEnough)
        .max(25),
    last_name: yup
        .string()
        .required(nameNotLongEnough)
        .min(2, nameNotLongEnough)
        .max(25),
    email: yup.string().email(invalidEmail).required(requiredMessage),
    password: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required(requiredMessage),
    phoneNumber: yup
        .string()
        .required(requiredMessage)
        .min(7, phoneNumberNotValid)
        .max(10, phoneNumberNotValid),

    allowAgreement: yup.bool().oneOf([true], acceptTerms),
    allowPromotion: yup.bool(),
    allowPromotionEmail: yup.bool(),
});
export const storeUserSchema = yup.object().shape({
    first_name: yup
        .string()
        .required(nameNotLongEnough)
        .min(2, nameNotLongEnough)
        .max(25),
    last_name: yup

        .string()
        .required(nameNotLongEnough)
        .min(2, nameNotLongEnough)
        .max(25),
    email: yup.string().email(invalidEmail).required(requiredMessage),
    allowAgreement: yup.bool().oneOf([true], acceptTerms),
    allowPromotion: yup.bool(),
    allowPromotionEmail: yup.bool(),
    countryCode: yup.string(),
    phoneNumber: yup
        .string()
        .required(requiredMessage)
        .min(7, phoneNumberNotValid)
        .max(10, phoneNumberNotValid),
});

export const loginSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(requiredMessage),
    password: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required(requiredMessage),
});
export const guestLoginSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(requiredMessage),
});
export const returnGuestLoginSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(requiredMessage),
    orderNo: yup.string().required(requiredMessage),
});
export const personalInfoSchema = yup.object().shape({
    name: yup
        .string()
        .required(requiredMessage)
        .min(3, nameNotLongEnough)
        .max(25),
    surname: yup
        .string()
        .required(requiredMessage)
        .min(3, nameNotLongEnough)
        .max(25),
    userBirthday: yup.date().required(requiredMessage),
    gender: yup.string(),
});

export const contactInfoSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(requiredMessage),
    countryCode: yup.string(requiredMessage).required(requiredMessage),
    address: yup
        .string()
        .min(8, addressNotLongEnough)
        .required(requiredMessage),
    phoneNumber: yup
        .string()
        .required(requiredMessage)
        .min(7, phoneNumberNotValid)
        .max(13, phoneNumberNotValid),
    allowAgreement: yup.bool().oneOf([true], acceptTerms),
});

export const forgetPasswordSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(),
});

export const resetPasswordSchema = yup.object().shape({
    newPassword: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required(),
    newPasswordAgain: yup
        .string()
        .min(5, passwordNotLongEnough)
        .max(32, passwordTooLong)
        .required()
        .oneOf([yup.ref('newPassword')], passwordAreNotSame),
});

export const contactUsSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(),
    message: yup.string().required().max(256, addressNotLongEnough),
    name: yup.string().required().min(3, nameNotLongEnough).max(25),
    surname: yup.string().required().min(3, nameNotLongEnough).max(25),
    allowAgreement: yup.bool().oneOf([true], acceptTerms),
});

export const creditCardSchema = yup.object().shape({
    cardNumber: yup
        .string()
        .required()
        .min(16, addressNotLongEnough)
        .max(16, addressNotLongEnough),
    cardHolderName: yup.string().required().min(3).max(25),
    cardExpirationMonth: yup.number().required(),
    cardExpirationYear: yup.number().required(),
    cardCvv: yup.number().min(3, nameNotLongEnough).max(3).required(),
    is3D: yup.bool(),
    isSaveCard: yup.bool(),
});

export const newsletterSchema = yup.object().shape({
    email: yup.string().email(invalidEmail).required(),
});
