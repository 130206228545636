import styled from 'styled-components';

import { useTranslation } from '@hooks';
import { ErrorMessage } from '@components/ui';
import { Caption } from '@components/styles/Typography';

const InputWrapper = ({
    background,
    error,
    errorMessage,
    mobile,
    name,
    notValid,
    placeholder,
    width,
    children,
    paddingZero,
    noBorder,
    margin,
    withoutLabel,
    large,
}) => {
    const { t } = useTranslation();
    return (
        <StyledInputWrapper
            background={background}
            mobile={mobile}
            notValid={notValid}
            width={width}
            paddingZero={paddingZero}
            error={error}
            noborder={noBorder}
            margin={margin}
            large={large}
        >
            {!withoutLabel && (
                <label htmlFor={name}>
                    <Caption size={large ? '13px' : null}>
                        {placeholder}
                    </Caption>
                </label>
            )}
            {children}
            {error && <ErrorMessage message={errorMessage || t(error)} />}
        </StyledInputWrapper>
    );
};

const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;

    width: ${({ width }) => width || '100%'};

    @media (max-width: 768px) {
        width: ${({ mobile, width }) => mobile || width};
    }

    label {
        margin-bottom: 3px;
    }

    input[type='date'] {
        min-width: calc(100% - 10px);
    }

    input {
        width: ${({ width }) => width || '100%'};
        height: 30px;
        border: none;
        border-bottom: ${({ theme, error }) =>
            error
                ? `1px solid ${theme.colors.error}`
                : `1px solid ${theme.colors.primary}`};
        margin-bottom: ${({ margin }) => margin || '20px'};
        border-radius: 0;

        background: ${({ background }) => background && 'unset'};
        border-bottom: ${({ notValid }) => notValid && '1.5px solid #ed4337'};
        padding: ${({ paddingZero }) => paddingZero && '0'};

        font-family: var(--futura-font);
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: ${({ theme, large }) => (large ? '13px' : theme.sizes.xs)};
        line-height: 16px;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.colors.primary};
        ::placeholder {
            font-family: var(--futura-font);
            color: black;
        }
        ::-webkit-date-and-time-value {
            min-width: 100%;
            display: flex;
            justify-content: flex-start;
        }

        @media (max-width: 1024px) {
            width: ${({ mobile, width }) => mobile || width};
            border: ${({ notValid, noBorder }) =>
                notValid ? '1px solid #ed4337' : noBorder && 'none'} !important;
            padding: ${({ paddingZero }) => (paddingZero ? '0' : '0 5px')};
            border-radius: 3px;
        }

        /* @media (max-width: 350px) {
        width: 280px;
    } */

        :focus {
            outline: none;
        }
    }

    select {
        width: ${({ width }) => width || 'max-content'};
        margin: 0 !important;
        max-width: 100%;
        color: ${({ theme }) => theme.colors.primary};
        border: none;
        outline: none;
        padding: 5px 10px;
        height: 34px;
        border-radius: 3px;
        font-size: 12px !important;
    }
`;

export default InputWrapper;
